<script>
import { onMount, onDestroy, tick } from 'svelte';
import ClipboardJS from 'clipboard';
import { appkey, apibase, apikey } from '../utils/config.js';

let cb;
let showToolTip = false;

onMount(() => {
  cb = new ClipboardJS('#share', {
    text: getURL,
  });
  cb.on('success', async () => {
    showToolTip = true;
  });
});

onDestroy(() => {
  cb.destroy();
});

function getURL() {
  let url = window.location.href;
  return `${url}${url.indexOf('?') > -1 ? '&' : '?'}appkey=${appkey}&apibase=${encodeURIComponent(apibase)}&apikey=${apikey}`;
}
</script>

<button id="share" title="Copy share URL" on:mouseenter={() => showToolTip = false}><i class="fas fa-share-alt"></i></button>
<p class:on={showToolTip}>Copied!</p>

<style>
button {
  position: absolute;
  top: 40px;
  right: 80px;
  border: 0 none;
  padding: 0;
  background: transparent;
  font-size: 20px;
  line-height: 33px;
  color: inherit;
}
p {
  margin: 0;
  position: absolute;
  top: 74px;
  right: 51px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 100px;
  padding: 5px 15px;
  font-size: 11px;
  opacity: 0;
}
.on {
  animation: tt 1s none;
}
@keyframes tt {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
