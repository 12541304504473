<script>
import { RELATIONS, LABELS, CATEGORY, CATEGORIES_FOR_RECOMMEND } from '../utils/constant.js';
import { appkey } from '../utils/config.js';

export let type;
export let region;
export let genderOption = null;
export let categoryOption = [];
export let subCategoryOption = undefined;
export let customCategoryOption = undefined;
export let flexmodeOption;
export let hashFilter = undefined;
export let onFilterChange;
export let cphashActive;
export let cphashEnabled;
export let cphashThreshold;
export let cphashFiltered;

const storedFilterOpen = window.localStorage.getItem('filteropen');
const GENDERS = {
  'gender.female': LABELS['gender.female'],
  'gender.male': LABELS['gender.male'],
  'gender.unisex': LABELS['gender.unisex'],
};
let isFilterOpen = storedFilterOpen === 'false' ? false : true;
let genders;
let categories = false;
let subCategories = false;
let customCategories = [];
$: {
  genders = Object.keys(GENDERS).map(gender => {
    return {
      label: typeof genderOption === 'string' ? gender : GENDERS[gender].code,
      name: GENDERS[gender]['en-US'],
    }
  });
}
$: {
  if (region === undefined) {
    categories = false;
  } else if (region.category && type === 'search') { // v1 search
    categories = Object.keys(CATEGORY).filter(id => {
      return CATEGORY[id].type === CATEGORY[region.category.code.toString()].type;
    }).map(code => ({
      label: parseInt(code),
      name: CATEGORY[code].str,
    }));
  } else if (region.category) { // v1 stylist
    categories = Object.keys(CATEGORY).filter(id => {
      return CATEGORIES_FOR_RECOMMEND.includes(region.category.code) && CATEGORIES_FOR_RECOMMEND.includes(parseInt(id));
    }).map(code => ({
      label: parseInt(code),
      name: CATEGORY[code].str,
    }));
  } else if (type === 'search' && RELATIONS[appkey]['c1_relation'][region.category1.label]) {  // v2 search
    categories = RELATIONS[appkey]['c1_relation'][region.category1.label].child.map(c2 => ({
      label: c2,
      name: LABELS[c2]['en-US'],
    }));
  } else {  // TODO: v2 stylist
    categories = false;
  }
}
$: {
  if (region === undefined) {
    subCategories = false;
  } else if (region.category && type === 'search' && CATEGORY[categoryOption]) { // v1 search
    subCategories = CATEGORY[categoryOption]['sub_categories']
      .map(s => ({
        label: s.id,
        name: s.str,
      }));
  } else if (region.category) { // v1 stylist
    subCategories = false;
  } else if (type === 'search' && RELATIONS[appkey]['c2_relation'][categoryOption]) {  // v2 search
    subCategories = RELATIONS[appkey]['c2_relation'][categoryOption].child
      .map(c3 => ({
        label: c3,
        name: LABELS[c3]['en-US'],
      }));
  } else {  // v2 stylist
    subCategories = false;
  }
  if (subCategories !== false && subCategories.map(c => c.label).indexOf(subCategoryOption) === -1) {
    subCategoryOption = undefined;
  }
}
$: {
  if (region === undefined) {
    customCategories = false;
  } else if (type === 'search' && customCategoryOption) {  // v2 search
    customCategories = [{
      label: customCategoryOption,
      name: customCategoryOption.replace('vc.', '').replace('_', ' '),
    }];
  } else {  // v2 stylist
    customCategories = false;
  }
}

function toggleFilter() {
  isFilterOpen = !isFilterOpen;
  window.localStorage.setItem('filteropen', isFilterOpen);
}
</script>

<div id="search-options" class:on={isFilterOpen}>
  <button class="toggle" on:click={toggleFilter}>Filter</button>
  <table>
    <tr>
      <th>GENDER</th>
      <td>
        {#each genders as gender}
        <label>
          <input type="radio" value={gender.label} bind:group={genderOption} on:change={onFilterChange}>
          <span>{gender.name}</span>
        </label>
        {/each}
      </td>
    </tr>
    {#if categories && categories.length > 0 && !(customCategories && customCategories.length > 0)}
    <tr>
      <th>CATEGORY</th>
      <td>
        {#each categories as category (category.label)}
        <label>
          <input type="radio" value={category.label} bind:group={categoryOption} on:change={onFilterChange}>
          <span>{category.name}</span>
        </label>
        {/each}
      </td>
    </tr>
    {/if}
    {#if subCategories && subCategories.length > 0 && !(customCategories && customCategories.length > 0)}
    <tr>
      <th>SUB CATEGORY</th>
      <td>
        <label>
          <input type="radio" value={undefined} bind:group={subCategoryOption} on:change={onFilterChange}>
          <span>none</span>
        </label>
        {#each subCategories as subCategory}
        <label>
          <input type="radio" value={subCategory.label} bind:group={subCategoryOption} on:change={onFilterChange}>
          <span>{subCategory.name}</span>
        </label>
        {/each}
      </td>
    </tr>
    {/if}
    {#if customCategories && customCategories.length > 0}
    <tr>
      <th>CUSTOM CATEGORY</th>
      <td>
        {#each customCategories as customCategory}
        <label>
          <input type="radio" value={customCategory.label} bind:group={customCategoryOption} on:change={onFilterChange}>
          <span>{customCategory.name}</span>
        </label>
        {/each}
      </td>
    </tr>
    {/if}
    {#if type === 'search'}
    <tr>
      <th>MODE</th>
      <td>
        <label>
          <input type="radio" value="filter" bind:group={flexmodeOption} on:change={onFilterChange}>
          <span>filter</span>
        </label>
        <label>
          <input type="radio" value="naive" bind:group={flexmodeOption} on:change={onFilterChange}>
          <span>naive</span>
        </label>
      </td>
    </tr>
    <tr>
      <th>HASH</th>
      <td>
        <label>
          <input type="radio" value={undefined} bind:group={hashFilter} on:change={onFilterChange}>
          <span>none</span>
        </label>
        <label>
          <input type="radio" value="image_hash" bind:group={hashFilter} on:change={onFilterChange}>
          <span>image hash</span>
        </label>
        <label>
          <input type="radio" value="cynhash" bind:group={hashFilter} on:change={onFilterChange}>
          <span>cynhash</span>
        </label>
      </td>
    </tr>
    {#if cphashActive}
    <tr class:off={!cphashActive}>
      <th>CPHASH</th>
      <td>
        <label>
          <input type="radio" value={true} bind:group={cphashEnabled} disabled={!cphashActive}>
          <span>Enable</span>
        </label>
        <label>
          <input type="radio" value={false} bind:group={cphashEnabled} disabled={!cphashActive}>
          <span>Disable</span>
        </label>
        <label>
          Threshold
          <input type="number" bind:value={cphashThreshold} disabled={!cphashActive}>
        </label>
        <span>Filtered: {cphashFiltered}</span>
      </td>
    </tr>
    {/if}
    {/if}
  </table>
</div>

<style>
.off * {
  color: #ccc;
}
input[type=number] {
  width: 3em;
}
</style>
