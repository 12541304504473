
<script type="text/javascript">
    import { onMount } from 'svelte';
    import Tags from "svelte-tags-input";
    import { errorMessage, } from '../utils/stores.js';

    let loading = false;

    let arrayProduct = [];

    let arrayCategory = [];
    let arrayAttribute = [];

    let arrayCategorySelected = [];
    let arrayAttributeSelected = [];
    let query = "";
    let next = '';
    let allType = true;

    function formatPrice(n) {
        return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'KRW' }).format(n);
    }



    function tagsCategoryOnTags(event) {
        arrayCategorySelected = event.detail.tags;
        calcQuery();
    }
    function tagsAttributeOnTags(event) {
        arrayAttributeSelected = event.detail.tags;
        calcQuery();
    }

    const groupBy = (xs, f) => xs.reduce((rv, x) => {
        (rv[f(x)] = rv[f(x)] || []).push(x);
        return rv;
    }, {});

    function calcQuery() {
        const clauseCategory = allType ? arrayCategorySelected.map(v => v.replace(/^(c\d)\.(.+)$/, '$1 = "$1.$2"')).join(" or ") : arrayCategorySelected.map(v => v.replace(/^(c\d)\.(.+)$/, '$1 = "$1.$2"')).join(" and ");
        const grouped = groupBy(arrayAttributeSelected, v => v.replace(/^([^.]+)\.(.+)/, "$1"));
        const clauseAttribute = Object.keys(grouped).map(v => {
            const array = grouped[v];
            return '(' + array.map(v => v.replace(/^([^.]+)\.(.+)$/, '"$1.$2" in $1')).join(" or ") + ')'
        }).join(" and ")
        query = [clauseCategory, clauseAttribute].filter(_ => _).join(" and ");

        next = '';
        arrayProduct = [];
        buttonMoreClick();
    }
    function buttonMoreClick() {
        if(loading)
            return;

        if(!next && arrayProduct.length)
            return;

        loading = true;
        (async () => {
            /*
                curl 'https://api.pxl.ai/v3/products?count=2' \
                    -H 'apikey: INTRdbfc77634964500b3b54cbd7dea4ed0a0d6ddc4acee39871425aca558c5d414e' \
                    --data-raw 'c3="c3.long_pants"' \
                    --compressed
             */
            const response = await fetch(
                next ?
                    'https://api.pxl.ai/v3' + next :
                    'https://api.pxl.ai/v3/products?count=100'
                , {
                method: 'post',
                headers: {
                    apikey: localStorage.getItem('apikey'),
                },
                body: query,
            });
            if(!response.status.toString().match(/2\d\d/)) {
                $errorMessage = '';
                return;
            }
            const json = await response.json();
            if (json?.products.length === 0) {
                $errorMessage = '검색된 결과가 없습니다.';
                loading = false;
                return;
            }
            next = json?.links?.next;
            arrayProduct = [...arrayProduct, ...json.products];
            loading = false;
        })();
    }

    function checkAll() {
        const all=document.getElementById('all');
        if(all.checked) {
            allType = true;
            calcQuery()
        }else {
            allType = false;
            calcQuery();
        }
    };

    buttonMoreClick();


    onMount(async () => {
        document.getElementById('tagsCategory')?.focus();

        window.addEventListener('scroll', function (e) {
            if(document.body.scrollHeight - window.scrollY - window.innerHeight - 1000 < 0)
                buttonMoreClick();
        });

        ['tagsCategory', 'tagsAttribute'].forEach((id) => {
            const tagsInput = document.getElementById(id);
            tagsInput.addEventListener('blur', () => {
                const matches = document.getElementById(`${id}_matchs`);
                if (matches) {
                    matches.style.display = 'none';
                }
            });
            tagsInput.addEventListener('keydown', (e) => {
                if (e.key !== "ArrowDown" && e.key !== "ArrowUp") {
                    return;
                }
                const matches = document.getElementById(`${id}_matchs`);
                if (matches) {
                    matches.style.display = 'block';
                    if (e.key === "ArrowDown") {
                        matches.children[0].focus();
                    } else {
                        const lastIndex = matches.children.length - 1;
                        matches.children[lastIndex].focus();
                    }
                }
            })
            tagsInput.addEventListener('focus', () => {
                const matches = document.getElementById(`${id}_matchs`);
                if (matches) {
                    matches.style.display = 'block';
                }
            });
        });

        const body = document.querySelector("body");
        body.addEventListener("click", (e) => {
            if (e.target.classList.contains("svelte-tags-input")) {
                return;
            }
            ['tagsCategory', 'tagsAttribute'].forEach((id) => {
                const matches = document.getElementById(`${id}_matchs`);
                if (matches) {
                    matches.style.display = 'none';
                }
            });
        });

        const appFile = {
            fashion2: {
                category: 'category2.json',
                attribute: 'attribute2.json',
            },
            fashion3: {
                category: 'category3.json',
                attribute: 'attribute3.json',
            },
        };

        const app = localStorage.getItem("app");
        const file = appFile[app];
        if(file) {
            arrayCategory = await (await fetch(`/json/${file.category}`)).json();
            arrayAttribute = await (await fetch(`/json/${file.attribute}`)).json();
        }
    });

</script>

<style>
    .inputs :global(.svelte-tags-input-layout) {
        border: 1px solid transparent;
        margin: 10px 5px;
    }
    .inputs :global(.svelte-tags-input) {
        border: 1px solid #CCC;
        border-radius: 5px;
        padding: 6px 10px;
    }
    .inputs :global(.svelte-tags-input::placeholder) {
        opacity: 1;
        color:#8e8e8e;
    }
    .arrayProduct {
        text-align: center;
    }

</style>
<div class="view2">
    <div class="inputs">
        <label for="all"><input on:click={checkAll} type="checkbox" id="all" name="all" checked> All Category</label>
        <Tags
                id="tagsCategory"
                addKeys={[9, 13]}
                removeKeys={[8, 27]}
                onlyUnique={true}
                minChars={0}
                onlyAutocomplete={false}
                labelShow
                placeholder={"ex) tops, bottoms, shoes, headwear, etc..."}
                labelText={"Category"}
                autoComplete={arrayCategory}
                on:tags={tagsCategoryOnTags}
        />
        <Tags
                id="tagsAttribute"
                addKeys={[9]}
                removeKeys={[8, 27]}
                onlyUnique={true}
                minChars={0}
                onlyAutocomplete={false}
                labelShow
                placeholder={"ex) color, pattern, fabric, decoration, etc..."}
                labelText={"Attribute"}
                autoComplete={arrayAttribute}
                on:tags={tagsAttributeOnTags}
        />
        <pre style="white-space: pre-wrap">query: '{query ? query : "all"}'</pre>
    </div>

    <div class="arrayProductLength">{arrayProduct.length} item(s).</div>
    <div class="arrayProduct">
        {#each arrayProduct as p}
            <a href={p.product_url} class="product" target="_blank">
                <div>
                    <div class="img" style="background-image: url('{p.image_urls[0]}')">
                        {p.name}
                    </div>
                    <div class="description_workaround">
                        <div class="name">{p.name.replace(/<\/?[^>]+(>|$)/g, "")}</div>
                        <div class="price">{formatPrice(p.price)}</div>
                    </div>
                </div>
            </a>
        {/each}
    </div>
</div>
