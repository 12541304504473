<script>
import { goto, params } from '@sveltech/routify';
import { APPS, FLEX_MODE } from '../utils/constant.js';
import { errorMessage, fileElement } from '../utils/stores.js';
import Detect from '../components/Detect.svelte';
import TypeTab from '../components/TypeTab.svelte';
import Filter from '../components/Filter.svelte';
import ProductList from '../components/ProductList.svelte';
import { appkey } from '../utils/config.js';

export let scoped;

let pxl = scoped.pxl;

let detectTime = 0;
let searchTime = 0;

let current_categories = undefined;
let current_gender = undefined;
let current_subcategory = undefined;
let current_customcategory;
let current_region = undefined;
let current_attributes = undefined;
let flexmodeOption = 'filter';
let hashFilter = undefined;
$: flexmode = FLEX_MODE[flexmodeOption];
let regions = [];
let analyze = [];

let image_url = $params.image_url;
let searchType = $params.type;
let selectedRegionId = $params.region;
$: handleParams($params);

let results;

let csvData = ['', $params.image_url];

// we send hash name to api server instead of filtering cphash on viewer side.
let filtered;
$: {
  cphashActive;
  cphashEnabled;
  cphashThreshold;
  filtered = filterResult(results);
  logCSVData(filtered);
}

let cphashActive = true;
let cphashEnabled = true;
let cphashThreshold = 10;
let cphashFiltered = 0;

function handleParams(p) {
  if (p.image_url !== undefined && image_url !== p.image_url) {
    setResult(p.image_url);
    return;
  } else if (selectedRegionId !== p.region) {
    selectedRegionId = p.region;
    setCurrentValueFromRegion();
    update_result();
    return;
  } else if (searchType !== p.type) {
    searchType = p.type;
    update_result();
    return;
  }
  setResult(p.image_url);
}

function setResult(url) {
  if (appkey === 'tonic1' && url !== undefined) {
    setTonicResult(url);
  } else {
    // FIXME: consider splitting into fashion component
    setFashionResult(url);
  }
}

async function setTonicResult(url) {
  image_url = url;
  let result = await pxl.tonic(url).catch(err => errorMessage.set(err));
  if (result) {
    results = result;
  }
}

async function setFashionResult(url) {
  detectTime = 0;
  let detectStart = performance.now();
  if (url !== undefined) {
    image_url = url;
    regions = await pxl.detect(image_url).catch(err => errorMessage.set(err));
  } else if ($fileElement !== null) {
    regions = await pxl.detect_by_file($fileElement);
    let imageFile = new FileReader();
    imageFile.onload = e => image_url = e.target.result;
    imageFile.readAsDataURL($fileElement.files[0])
  } else {
    $errorMessage = 'No image URL or file.';
    $goto('/');
    return;
  }
  detectTime = performance.now() - detectStart;

  if (regions === undefined || regions.length === 0) {
    $errorMessage = 'Detect Failed.';
    $goto('/');
    return;
  }
  let region = regions.find(r => r.id.toString() === selectedRegionId);
  if (!region) {
    region = regionArgMax(regions);
    $goto(`/view/?${$params.image_url ? `image_url=${encodeURIComponent($params.image_url)}&` : ''}type=${$params.type}&region=${region.id}`);
    setAnalyze(url);
    return;
  }
  setCurrentValueFromRegion();
  update_result();
  setAnalyze(url);
}

async function setAnalyze(url) {
  let result;
  if (url != undefined) {
    result = await pxl.analyze(image_url);
  } else if ($fileElement !== null) {
    result = await pxl.analyze_by_file($fileElement);
  }
  if (result) {
    analyze = result;
  }
}

// https://github.com/ocsdk/shuqstyle-js-sdk/blob/master/pxl.js#L574
function regionArgMax(l) {
  // Favor big rectangles. The abs() call here is overkill, but I don't trust the data.
  return argmax(l, (e) => {
    const score = e.score || e.category.score;
    return Math.abs(e.rx2 - e.rx1) * Math.abs(e.ry2 - e.ry1) * score
  });
}

// https://github.com/ocsdk/shuqstyle-js-sdk/blob/master/pxl.js#L536
function argmax(l, f) {
  // FIXME: This should be made to work with NodeLists.
  let ms = f(l[0]),
      mo = l[0];

  l.map((e) => {
    let i = f(e);

    if (i > ms) {
      ms = i;
      mo = e;
    }
  });

  return mo;
}

function setCurrentValueFromRegion() {
  let region = regions.find(r => r.id.toString() === selectedRegionId);
  if (!region) {
    return;
  }
  current_region = region;
  current_gender = region.gender.label || region.gender.code;
  current_categories = region.category2 ? region.category2.label : region.category.code;
  current_subcategory = region.category3 ? region.category3.label : region.sub_category ? region.sub_category.code : undefined;
  current_customcategory = region.virtual_category ? region.virtual_category.label : undefined;
  current_attributes = [];
  flexmodeOption = 'filter';
  hashFilter = undefined;
}

async function update_result() {
  searchTime = 0;
  let searchStart = performance.now();
  results = undefined;
  if ($params.type === 'search') {
    results = await pxl.search(current_region.id, current_gender, current_categories,
      current_subcategory, current_customcategory, current_attributes, flexmode, hashFilter)
      .catch(err => errorMessage.set(err));
  } else if (['recommend', 'recommend-pair', 'recommend-multi'].indexOf($params.type) > -1) {
    const mode = {
      'recommend': 0,
      'recommend-pair': 1,
      'recommend-multi': 2,
    };
    results = await pxl.recommend(current_region.id, mode[$params.type], current_gender, current_categories)
      .catch(err => errorMessage.set(err));
  }
  searchTime = performance.now() - searchStart;
}

function filterResult(results) {
  if (results === undefined || $params.type !== 'search') {
    return results;
  }
  cphashActive = true;
  cphashFiltered = 0;
  if (!cphashEnabled) {
    return results;
  }
  if (results.filter(r => r.cphash !== undefined).length === 0) {
    cphashActive = false;
    return results;
  }
  let filtered = []
  for (let r of results) {
    if (!r.cphash) {
      filtered.push(r);
      continue;
    }
    let dup = filtered
      .filter(f => f.cphash !== undefined)
      .filter(f => cphashCompare(r.cphash, f.cphash, cphashThreshold));
    if (dup.length === 0) {
      filtered.push(r);
    } else {
      cphashFiltered++;
    }
  }
  return filtered;
}

function cphashCompare(h1, h2, ht=10) {
  let d = 0, m = 0;
  for (let i = 0; i < h1.length; i+= 4) {
    m = parseInt(h1.slice(i, i + 4), 16) ^ parseInt(h2.slice(i, i + 4), 16);
    while (m > 0) {
      m &= m - 1;
      d += 1;
    }
  }
  return d <= ht;
}

function logCSVData(results) {
  if (csvData[1] !== $params.image_url)
    csvData = ['', $params.image_url];
  if (csvData.length === 2) {
    for (const i in results) {
      if (i === '4')
        break
      csvData.push(...['', results[i].image_url, results[i].product_code, results[i].product_url])
    }
    if (csvData.length > 2) {
      console.log('csv for 자동진열')
      console.log(csvData.join(','))
    }
  }
}
</script>

<div id="view">
  {#if appkey === 'tonic1'}
    <div id="query-image">
      <img src={image_url} class="query-image" alt="">
    </div>
    <div id="app-type">
      {APPS[appkey].name}
    </div>
  {:else}
  <Detect image_url={image_url} regions={regions} analyze={analyze} current_region={current_region} />
  <TypeTab selectedRegionId={selectedRegionId} />
  <Filter
    type={$params.type}
    region={current_region}
    bind:genderOption={current_gender}
    bind:categoryOption={current_categories}
    bind:subCategoryOption={current_subcategory}
    bind:customCategoryOption={current_customcategory}
    bind:flexmodeOption={flexmodeOption}
    cphashActive={false}
    bind:cphashEnabled={cphashEnabled}
    bind:cphashThreshold={cphashThreshold}
    cphashFiltered={cphashFiltered}
    bind:hashFilter={hashFilter}
    onFilterChange={update_result} />
  {/if}
  <ProductList
    type={appkey === 'tonic1' ? 'search' : $params.type}
    results={filtered}
  />
  {#if detectTime > 0 && searchTime > 0}
  <p class="performance">Detect: {parseInt(detectTime)}ms / Search: {parseInt(searchTime)}ms</p>
  {/if}
</div>

<style>
#view {
  position: relative;
}
.performance {
  text-align: center;
  font-size: 11px;
  color: #999;
}
</style>
