<script>
import { tick } from 'svelte';
import { appkey, apibase, apikey, demoImages, resultCount, saveSettings } from '../utils/config.js';
import { APPS } from '../utils/constant.js';
import { demoImages as demoImageStore } from '../utils/stores.js';

let _appkey, _apibase, _apikey, _demoImages, _resultCount;
$demoImageStore = demoImages;

let isSettingOpen = false;
let modal;
$: isJSONMalform = _demoImages !== undefined && _demoImages.length > 0 && !isValidJSON(_demoImages);

function dimmerClick(event) {
  if (modal.contains(event.target)) {
    return;
  }
  closeModal();
}

function openModal() {
  _appkey = appkey;
  _apibase = apibase;
  _apikey = apikey;
  _demoImages = demoImages;
  _resultCount = resultCount;
  isSettingOpen = true;
}

function closeModal() {
  isSettingOpen = false;
}

function updateAPIBase() {
  _apibase = APPS[_appkey].apibase;
}

function isValidJSON(str) {
  try {
    JSON.parse(str);
  } catch(e) {
    return false;
  }
  const json = JSON.parse(str);
  return Array.isArray(json) && json.length > 0;
}

function bodyKeyup(event) {
  if (isSettingOpen && event.keyCode === 27) {
    closeModal();
  }
}

function saveSetting() {
  $demoImageStore = _demoImages;
  saveSettings({
    appkey: _appkey,
    apibase: _apibase,
    apikey: _apikey,
    demoImages: _demoImages,
    resultCount: _resultCount,
  });
  closeModal();
  location.reload();
}
</script>

<svelte:body on:keyup={bodyKeyup} />
<button id="setting-button" on:click|preventDefault={openModal}><i class="fas fa-cog"></i></button>
<div id="api-setting" class:on={isSettingOpen} on:click={dimmerClick}>
  <form class="block shadow-sm text-align-left" on:submit|preventDefault={saveSetting} bind:this={modal}>
    <div class="form-item">
      App
      <div class="label-group">
        {#each Object.keys(APPS) as item}
        <label>
          <input type="radio" name="app" bind:group={_appkey} value={item} on:change={updateAPIBase}>
          {APPS[item].name}
        </label>
        {/each}
      </div>
    </div>
    <label>
      API base
      <input type="text" bind:value={_apibase}>
    </label>
    <label>
      API key
      <input type="text" id="input_apikey" bind:value={_apikey}>
    </label>
    <label>
      Demo images
      <textarea bind:value={_demoImages} class:err={isJSONMalform} placeholder="[&quot;imageURL&quot;, &quot;imageURL&quot;, ... ]"></textarea>
    </label>
    <label>
      Result count
      <input type="number" bind:value={_resultCount}>
    </label>
    <input type="submit" value="Apply" disabled={isJSONMalform}>
  </form>
</div>

<style>
#setting-button {
  position: absolute;
  top: 40px;
  right: 40px;
  border: 0 none;
  padding: 0;
  background: transparent;
  font-size: 20px;
  line-height: 33px;
  color: inherit;
}
#api-setting {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 0, 0.6);
  display: none;
  align-items: center;
  justify-content: center;
}
#api-setting.on {
  display: flex;
}
#api-setting form {
  width: 600px;
  background-color: #fff;
  max-height: 100%;
  overflow: auto;
}
#api-setting .form-item,
#api-setting label {
  font-size: 14px;
  font-weight: 600;
}
#api-setting .label-group {
  display: block;
  margin: 10px 0 20px;
}
#api-setting .label-group label {
  margin-left: 2.5em;
  font-weight: normal;
}
#api-setting .label-group label:first-child {
  margin-left: 0;
}
#api-setting .label-group input {
  margin-right: 0.4em;
}
#api-setting input[type=text],
#api-setting input[type=submit],
#api-setting textarea {
  width: 100%;
  height: 40px;
  padding: 10px;
  margin: 10px 0 20px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  -webkit-appearance: none;
  color: #555;
  font-weight: normal;
}
#api-setting textarea {
  height: 100px;
}
#api-setting textarea.err {
  border-color: #c00;
}
#api-setting input[type=submit] {
  margin-top: 25px;
  margin-bottom: 0;
}
#api-setting input[type=submit]:disabled {
  opacity: 0.4;
}
[type=number] {
  display: block;
  width: 4em;
  margin: 10px 0 0;
}
</style>
