<script>
import { url, params } from '@sveltech/routify';

export let product;

function formatPrice(n) {
  return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'KRW' }).format(n);
}
</script>

<div class="grid-item">
  <img class="thumbnail" src={product.image_url} decode="async" alt="">
  <a target="_blank" href={$url(`/view/?image_url=${encodeURIComponent(product.image_url)}&type=${$params.type}`)}>
    <div class="view-button"><img src="/images/icon_search.svg" alt="Search"></div>
  </a>
  <div class="name"><a target="_blank" href="{product.product_url}">{product.name.replace(/<\/?[^>]+(>|$)/g, "")}</a></div>
  <div class="price"><a target="_blank" href="{product.product_url}">{formatPrice(product.price)}</a></div>
  <div class="product-code">{product.product_code}</div>
</div>

<style>
.grid-item {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background: #FFF;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  border: 2px solid #ececec;
}
.grid-item .thumbnail {
  width: 100%;
  height: 200px;
  display: block;
  object-fit: cover;
}
.view-button {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  background: #9F6DFA;
}
.grid-item .view-button > img {
  width: 20px;
  height: 20px;
  margin: 10px;
}
.grid-item > .name {
  margin: 10px;
  max-height: 2.8em;
  min-height: 2.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.grid-item > .name > a {
  font-size: 12px;
  color: #555;
  text-decoration: none;
  word-break: break-all;
}
.grid-item > .price {
  padding: 0 10px 10px 10px;
  text-align: right;
}
.grid-item .price > a {
  font-size: 12px;
  color: #9F6DFA;
  text-decoration: none;
}

.grid-item > .product-code {
  margin: 10px;
  max-height: 2.8em;
  min-height: 2.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  word-break: break-all;
  font-size: 12px;
  color: #555;
}

@media screen and (min-width: 768px) {
  .view-button { display: none; }
  .grid-item:hover .view-button { display: inline-block; }
}
</style>
