<script>
import { url } from '@sveltech/routify';
</script>

<div class="navbar" id="navbar">
  <a href={$url('/')}>
    <img src="/images/logo_pxl_b.png" alt="PXL">
  </a>
  <a href={$url('/products')}>
    <i class="fas fa-filter"></i>
  </a>
</div>

<style>
.navbar {
  padding: 20px;
  overflow: auto;
}
@media (min-width: 480px) {
  .navbar {
    padding: 40px;
  }
}
.navbar > a {
  float: left;
  display: inline-block;
}
.navbar > a > img {
  height: 20px;
  margin: 5px 0 5px 0;
  vertical-align: middle;
}
@media (min-width: 480px) {
  .navbar > a > img {
    height: 30px;
  }
}
</style>
