<script>
import { errorMessage } from '../utils/stores.js';

let box;

function close() {
  errorMessage.set(null);
}

function bodyKeyup(event) {
  if (event.keyCode === 27) {
    close();
  }
}

function dimmerClick(event) {
  if (box.contains(event.target)) {
    return;
  }
  close();
}
</script>

<svelte:body on:keyup={bodyKeyup} />

{#if $errorMessage !== null}
<div id="error-message" on:click={dimmerClick}>
  <div class="box" bind:this={box}>
    <p class="icon">
      !      
    </p>
    <p class="message">
      {$errorMessage === null ? '' : $errorMessage}
    </p>
    <p class="button">
      <button on:click={close}>Close</button>
    </p>
  </div>
</div>
{/if}

<style>
#error-message {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #c00;
}
.box {
  background: #fff;
  padding: 25px;
  min-width: 300px;
  text-align: center;
}
.icon {
  font-size: 3em;
  display: block;
  margin: 0;
}
.message {
  margin: 25px 0;
}
.button {
  margin: 0;
}
button {
  padding: 5px 10px;
  box-sizing: border-box;
  font-size: 14px;
  border: 1px solid #dcdcdc;
  border-radius: 0;
  -webkit-appearance: none;
  color: #555;
  font-weight: normal;
}
</style>
