<script>
import { errorMessage } from '../utils/stores.js';
import { LABELS } from '../utils/constant.js';
import ProductItem from './ProductItem.svelte';

export let type;
export let results;

function getCategoryName(label) {
  if (typeof label === 'string' && label.indexOf('.') > -1) {
    return LABELS[label]['en-US'];
  }
  return LABELS[LABELS[label]]['en-US'];
}
</script>

  <div id="result-list" class="{type}" class:loading={results === undefined}>
    {#if type === 'recommend-pair' && results !== undefined && Object.keys(results).length > 0}
      {#each Object.keys(results) as label}
        <h2>{getCategoryName(label)}</h2>
        <div class="grid-list">
          {#each results[label] as product}
            <ProductItem product={product} />
          {/each}
        </div>
      {/each}
    {:else if type === 'recommend-multi' && results !== undefined && results.length > 0}
      {#each results as set, i}
      <div class="set">
        <h2>set {i + 1}</h2>
        <div class="grid-list">
          {#each set as product}
            <ProductItem product={product} />
          {/each}
        </div>
      </div>
      {/each}
    {:else if (type === 'search' || type === 'recommend') && results !== undefined && results.length > 0}
      <div class="grid-list">
        {#each results as product}
          <ProductItem product={product} />
        {/each}
      </div>
    {:else if results !== undefined}
      No results found.
    {/if}
  </div>

<style>
.recommend-multi {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(466px, 1fr));
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}
.recommend-multi h2 {
  margin-top: 0;
}
</style>
